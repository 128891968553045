import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './index.scss';

import {
  Section,
  Container,
  Row,
  Column,
  Text,
  ScrollTo,
  Image,
} from 'components/atoms';

import Breadcrumbs from 'components/molecules/Breadcrumbs';

const BannerIndustry = ({ data, className, breadcrumbsData }) => {
  const { title, acf } = data;

  const text = 'Learn more';
  const path = '#descriptionIndustry';

  const { subtitle, banner, banner_image_mobile, use_default_image_on_mobile } =
    acf || {};
  const { source_url, media_details, alt_text, title: image_title } =
    banner || {};
  const { media_details: mobile_media_details, source_url: mobileBanner } =
    banner_image_mobile || {};

  return (
    <Section className={cn('banner-industry', [className])}>
      <Container>
        <Row>
          <Column
            className="banner-industry__description offset-lg-0 offset-1"
            lg="12"
            col="10"
          >
            <Text
              color="transparent-green"
              className="banner-industry__description__title"
              tag="h1"
            >
              {title}
            </Text>
          </Column>
          <Column
            className="banner-industry__description offset-lg-0 offset-2"
            lg="12"
            col="8"
          >
            <Text
              color="transparent-green"
              className="banner-industry__description__subtitle body-lg"
            >
              {subtitle}
            </Text>
          </Column>
        </Row>
        <ScrollTo
          className="banner-industry__button"
          title={text}
          anchor={path}
        />
        <Breadcrumbs
          {...breadcrumbsData}
          className="banner-industry__breadcrumbs"
        />
      </Container>
      <div className="banner-industry__media">
        <div className="banner-industry__media-wrapper">
          <Image
            className={cn({
              'hide-in-mobile': !use_default_image_on_mobile,
            })}
            src={source_url}
            alt={alt_text}
            title={image_title}
            image={media_details}
          />

          {!use_default_image_on_mobile && (
            <Image
              className="show-in-mobile"
              src={mobileBanner}
              alt={alt_text}
              title={image_title}
              image={mobile_media_details}
            />
          )}
        </div>
      </div>
    </Section>
  );
};

BannerIndustry.defaultProps = {
  className: '',
};

BannerIndustry.propTypes = {
  data: PropTypes.object.isRequired,
  breadcrumbsData: PropTypes.object,
  className: PropTypes.string,
};

export default BannerIndustry;
